import React, {useState, useRef} from 'react';

import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useForm, Controller } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import {Divider} from 'primereact/divider';

import { getConnection } from '../../services/api/erp';
import { login } from '../../services/auth';

import '../../assets/style/pages/login/index.scss';

import logo from '../../assets/image/logo-bioshop.png';

export const PageLogin = (props) => {

    const defaultValues = {
        cnpj:'',
        user:'',
        password:''
    }

    const [isLoad, setIsLoad] = useState(false);

    const form = useForm({ defaultValues });
    const control =  form.control;
    const errors = form.formState.errors;
    const handleSubmit = form.handleSubmit;

    const toast = useRef();

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onSubmit = async (_data) => {

        setIsLoad(true);

        console.log(_data);

        const data =  await getConnection(_data.cnpj, _data.user, _data.password);
        
        if(data.data){
            login(data.data);
            props.history.push('/user');
        }else{
            toast.current.show({severity: 'error', summary: 'Erro', detail: 'Usuário e/ou senha inválidos.'});
        }

        setIsLoad(false);
    }

    return(
        <div className="page-login">
            <div className="box">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="p-grid">
                        <div className="p-col-12 p-d-flex p-jc-center p-mb-4 p-mt-4">
                            <img className="logo" src={logo} alt="Bioshop" />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <label htmlFor="cnpj">CNPJ</label>
                            <Controller name="cnpj" control={control} rules={{ required: 'Campo obrigatório' }} render={({ field, fieldState }) => (
                                <InputMask  id={field.name} mask="99.999.999/9999-99" value={field.value} onChange={(e)=> field.onChange(e.value)} placeholder="Insira o CNPJ" />
                            )} />
                            {getFormErrorMessage('cnpj')}
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col-12">
                            <label htmlFor="user">Usuário</label>
                            <Controller name="user" control={control} rules={{ required: 'Campo obrigatório' }} render={({ field, fieldState }) => (
                                <InputText  id={field.name} value={field.value} onChange={(e)=> field.onChange(e.target.value)} placeholder="Insira o usuário" />
                            )} />
                            {getFormErrorMessage('user')}
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col-12">
                            <label htmlFor="password">Senha</label>
                            <Controller name="password" control={control} rules={{ required: 'Campo obrigatório' }} render={({ field, fieldState }) => (
                                <Password toggleMask={true} feedback={false}  id={field.name} value={field.value} onChange={(e)=> field.onChange(e.target.value)} placeholder="Insira sua senha" />
                            )} />
                            {getFormErrorMessage('password')}
                        </div>
                    </div>

                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-d-flex p-jc-center">
                            <Button loading={isLoad} iconPos="right" type="submit" label="Entrar" />
                        </div>
                    </div>
                    <Divider />
                    <div className="p-d-flex p-jc-center">
                            <div style={{fontSize:'0.8em'}}>
                                Versão 1.0
                            </div>
                    </div>
                </form>
            </div>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}