import { URL_API } from "..";

import { getToken } from "../../auth";

export const getCompanys = async () => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}companys`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;
}

export const getClients = async () => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}companys/clients`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;
}

export const getBuys = async (_data = null) => {


    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header
    };

    let paramsget = '';
    
    if(_data)
        paramsget = `data=${_data}`;


    const request = await fetch(`${URL_API}companys/buys?${paramsget}`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;
}

export const getRequests = async () => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}companys/requests`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;
}