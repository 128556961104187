import React, {useEffect, useRef} from 'react';  
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';

import { MenuUser } from '../../componets/menu/user';

import { PageDashboard } from './dashboard';
import { logout } from '../../services/auth';

import '../../assets/style/pages/user/index.scss';

export const PageUser = (props) => {

    const menuShow = useRef();


    const setMenuShow = () => {
        menuShow.current.classList.toggle('active');
        menuShow.current.querySelector('ul').classList.toggle('active');
    }

    const leftContents = (<>
        <Button onClick={()=>setMenuShow()} icon="pi pi-bars" className="p-button-plain p-button-text p-button-sm p-buttton-rounded" />
    </>);

    const rightContents = (<>
        <Button onClick={()=>{}} label={'Usuario'} className="p-button-plain p-button-text p-button-sm" />
        <Button onClick={()=>{
            logout();
            props.history.push('/');
        }} icon="pi pi-power-off" className="p-button-danger p-button-text p-button-sm p-buttton-rounded" />
    </>);

    useEffect(()=>{

        if(window.screen.availWidth > 500){
            menuShow.current.classList.add('active');
            menuShow.current.querySelector('ul').classList.add('active');
        }

    },[]);

    return (
        <div className="page-user">
            <header>
                <Toolbar left={leftContents} right={rightContents} />
            </header>
            <section>
                <nav ref={menuShow}>
                    <MenuUser {...props} />
                </nav>
                <main>
                    <BrowserRouter key={`${props.location.pathname.replace(/\//gi,'_')}`} basename='/user'>
                        <Switch>
                            <Route exact path="/teste" component={(p)=> <>Page</>} />
                            <Route path="*" component={(p)=> <PageDashboard {...p} {...props} /> } />
                        </Switch>
                    </BrowserRouter>
                </main>
            </section>
            <footer>
                Versão
            </footer>
        </div>
    )
}