import React from 'react';

import { userMenu } from '../../../services/provider/menu';

import '../../../assets/style/components/menu/user/index.scss';

export const MenuUser = (props) => {
    return (
        <div className="menu-user">

            <ul>
            {
                userMenu(props).map(item =>
                    <li className={`${item.command ? 'click' : 'title'}`} onClick={item?.command} >
                        { item?.icon?.length > 0 ? <div className="icon"><i className={item.icon}></i></div> : ''}
                        { item?.label?.length > 0 ? <div className="text">{item.label}</div> : ''}
                    </li>
                )
            }
            </ul>

        </div>
    )
}