import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { PageLogin } from '../../../pages/login';
import { PageUser } from '../../../pages/user';

export const MainRouter = (props) => {
    return(
        <BrowserRouter basename='/'>
            <Switch>
                <Route exact path="/user/:page?" component={(p)=> <PageUser {...p} {...props} />} />
                <Route path="*" component={(p)=> <PageLogin {...p} {...props} /> } />
            </Switch>
        </BrowserRouter>
    )
}