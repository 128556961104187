import React from 'react';

import { ProgressBar } from 'primereact/progressbar';

import { Divider } from 'primereact/divider';

import '../../../../assets/style/components/card/dashboard/header/index.scss';

export const CardDashboardHeader = (props) => {
    return (
        <div style={{backgroundColor:(props?.cardcolor ? props.cardcolor : 'var(--blue-100)'), color:(props?.textcolor ? props.textcolor : '#111')}} className="card-dashboard-header">
            <div className="title">
                {`${props?.title}`}
                <Divider />
            </div>
            <div className="icon">
                <i className={`${props?.icon}`}></i>
            </div>
            <div className="content-value">
                {
                    !(/null/).test(props.value) ?
                        props.value
                    :
                        <div style={{width:'250px'}}>
                            <ProgressBar style={{height:'5px'}} mode="indeterminate" />
                        </div>
                }
            </div>
        </div>
    )
}