import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { addLocale } from 'primereact/api';

import Chart from 'chart.js/auto';

import { locale } from '../../../services/locale';

import { getCompanys, getClients, getBuys, getRequests } from '../../../services/api/client';

import { CardDashboardHeader } from '../../../componets/cards/dashboard/header';


import '../../../assets/style/pages/user/dashboard/index.scss';

export const PageDashboard = (props) => {


    addLocale('br', locale.br);

    const [companysData, setCompanysData] = useState(null);
    const [companysSelecteds, setCompanysSelected] = useState();
    const [clientsData, setClientsData] = useState(null);
    const [buysData, setBuysData] = useState(null);
    const [requestsData, setReuqestsData] = useState(null);
    const [version, setVersion] = useState(0);

    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [endChart, setEndChart] = useState(new Date());

    const [chartHoursLoad, setChartHoursLoad] = useState(false);

    const chartBuys = useRef();
    const chartBuysData = useRef();
    const chartBuysRef = useRef();

    const chartBuysVend = useRef();
    const chartBuysDataVend = useRef();
    const chartBuysRefVend = useRef();

    const chartbuybyhours = useRef();
    const chartbuybyhoursCreated = useRef();

    let colorsList = [];
    colorsList.length = 100;
    colorsList.fill(0);

    colorsList = colorsList.map(item => {
        let datacolor;

        let str = '0123456789ABCDEF';
        let temp = str.split('');
        temp = temp.sort(()=> Math.random() - 0.5);
        temp.length = 6;
        datacolor = `#${temp.join('')}`;
        return datacolor;
    } )


    const colors = useRef();
    colors.current = colors.current ? colors.current : colorsList;


    chartBuysData.current = {
        type: 'pie',
        data:{
            labels: ['Vendas', 'Cancelados'],
            datasets:[
                {
                    label: "Vendas completas",
                    backgroundColor: ['rgba(212, 236, 213, 0.8)', 'rgba(250, 201, 201, 0.8)'],
                    border: ['rgb(12, 26, 136)',  'rgb(212, 236, 213)'],
                    data: [0, 0]
                }
            ]
        },
        options: {
            legend:{
                display: true,
                title:{
                    display: false
                }
            }
            
        }
    }

    chartBuysDataVend.current = {
        type: 'bar',
        data:{
            labels: [],
            datasets:[
                {
                    label: "Vendas",
                    backgroundColor: 'rgba(250, 201, 201, 0.8)',
                    border: 'rgb(12, 26, 136)',
                    data: []
                }
            ]
        },
        options: {
            legend:{
                display: false,
                title:{
                    display: false
                }
            }
            
        }
    }

    const formatToMoney = (_val) => {

        if(_val)
            return `R$ ${parseFloat(_val).toFixed(2)}`.replace('.',',');
        else
            return `R$ ${parseFloat(~~_val).toFixed(2)}`.replace('.',',');
    
    }

    const getDataDash = useCallback(()=>{

        const getData = async () => {
            const companys = await getCompanys();
            setCompanysData(companys?.data);

            const clients = await getClients();
            console.log(clients.data);
            setClientsData(clients?.data?.length);

            const buys = await getBuys();
            const price =  (buys.data || []).reduce((c, n) => parseFloat(c) + parseFloat(n.total) , 0);
            setBuysData(formatToMoney( (buys.data || []).length > 0 ? price : 0));

            const requests = await getRequests();
            setReuqestsData(requests?.data?.length);

            const completed =  (buys.data || []).filter(item => ~~item.cancelado === 0);
            const cancel = (buys.data || []).filter(item => ~~item.cancelado === 1);

            chartBuysData.current.data.datasets[0].data = [completed.length, cancel.length];
            chartBuysRef.current?.destroy();
            chartBuysRef.current = new Chart(chartBuys.current.getContext('2d'), chartBuysData.current);

            let labels =  (buys.data || []).map(item => item.vendedor_nome);

            labels = [...new Set(labels)];

            chartBuysDataVend.current.data.labels = labels;
            chartBuysDataVend.current.data.datasets[0].data = labels.map(item => {
                const total = buys.data.filter(el => el.vendedor_nome === item);
                return total.length;
            });
            chartBuysRefVend.current?.destroy();
            chartBuysRefVend.current = new Chart(chartBuysVend.current.getContext('2d'), chartBuysDataVend.current);



            setVersion(new Date().getTime());
            
        }  

        getData();

    }, [])

    useEffect(() => {
        

        const getData = async () => {


            setChartHoursLoad(true);

            let listHours = [];
            listHours.length = 24;
            listHours.fill(0);
            listHours = listHours.map((it, i )=> ({start: `${i}:00:00`, end: `${i}:59:59`}));

            const companyList = [];
            companysSelecteds?.map(item => {
                const data = companysData.filter(el => ~~el.id === ~~item)[0];
                if(data){
                    companyList.push(data);
                }
                return item;
            })

            let companysDataList = [{
                id: 0,
                label: 'Selecione a(s) empresa(s)',
                backgroundColor: `rgba(0, 0, 0, 0)`,
                borderColor: `rgba(0, 0, 0, 0)`,
                data: []
            }];
            
            let listRequests = [];

            console.log('Data', endChart)

            if(companyList.length > 0){
                companysDataList = companyList?.map((c, id) => {
                    
                    listRequests[id] = [];

                    const temp = listHours.map(h => getBuys(btoa(JSON.stringify({where:`empresa_id=${c.id} and horas between '${h.start}' and '${h.end}' and data = '${endChart.toISOString().slice(0, 10).replace('T', ' ')}'`}))));

                    listRequests[id] = Promise.all(temp);

                    return (
                        {
                            id: c.id,
                            label: c.razao,
                            backgroundColor: colors.current[id],
                            borderColor: colors.current[id],
                            data: []
                        }
                    )
                })
            }

            const dataRequest = await Promise.all(listRequests);

            companysDataList?.map((item, id ) => {
                const total = dataRequest[id]?.map(el=> {
                    return el.data.reduce((c, n)=> {
                        return parseFloat(c) + parseFloat(n.total)
                    }, 0);
                });

                companysDataList[id].data = total;

                return item;
            })

            const chartLineHours = {
                type: 'line',
                data:{
                    labels: listHours.map((it, id)=> id),
                    datasets: companysDataList
                },
                options: {
                    maintainAspectRatio: true,
                    plugins:{
                        legend:{
                            display: true,
                            position:'bottom'
                        },
                        title:{
                            text:'Total vendas por hora',
                            display: true
                        }
                    },
                    borderWidth: 5,
                    animation: true,
                    
                }
            }


            chartbuybyhoursCreated.current?.destroy();

            chartbuybyhoursCreated.current =  new Chart(chartbuybyhours.current.getContext('2d'), chartLineHours);

            setChartHoursLoad(false);

        }

       getData();

    }, [chartbuybyhoursCreated, companysData, companysSelecteds, endChart])


    useEffect(()=>{

        

        console.log( '@', chartBuysData.current.data.datasets);

        if(chartBuys?.current && version > 0){
            // chartBuysRef.current = new Chart(chartBuys.current.getContext('2d'), chartBuysData.current);
            //console.log('Create Graph', chartBuysData.current);
            //chartBuysRef.current.update();
        }

    }, [version])


    useEffect(()=> {

        getDataDash();

    }, [getDataDash])

    return (
        <div className="page-user-dashboard">
            <Divider type="dashed">
                <strong style={{fontSize:'1.5em', fontWeight:700}}>DASHBOARD</strong>
            </Divider>
            <header>
                <CardDashboardHeader title="Empresas" icon="pi pi-briefcase" value={companysData ? ~~companysData?.length : null} cardcolor="var(--orange-100)" textcolor="var(-orange-900)" />
                <CardDashboardHeader title="Clientes" icon="pi pi-users" value={clientsData} cardcolor="var(--teal-100)" textcolor="var(--teal-900)" />
                <CardDashboardHeader title="Pedidos" icon="pi pi-list" value={requestsData} cardcolor="var(--bluegray-100)" textcolor="var(--bluegray-900)" />
                <CardDashboardHeader title="Total vendas" icon="pi pi-dollar" value={buysData} cardcolor="var(--pink-100)" textcolor="var(--pink-900)" />
            </header>
            <div className="p-grid p-mt-3 p-mb-3">
                <div className="p-col-12">
                    <div className="toolbar-chart p-mt-4 p-mb-2">
                        <MultiSelect placeholder="Selecione a(s) empresa(s)" value={companysSelecteds} onChange={(e) => setCompanysSelected(e.value)} options={(companysData ? companysData.map(item => ({label: item.razao, value: item.id})) : [])} />
                        <Calendar monthNavigator yearNavigator yearRange={`${new Date().getFullYear() - 50}:${new Date().getFullYear()}`} showIcon={true} value={endChart} onChange={(e)=> setEndChart(e.value)} dateFormat="dd/mm/yy" locale="br" className="p-button-sm" />
                    </div>
                    <div className={`${chartHoursLoad ? 'load' : '' }`}>
                        <canvas ref={chartbuybyhours} width="1000" height="300" style={{minWidth:'100%', margin:'0 auto'}} />
                    </div>
                </div>
            </div>
            <div className="toolbar p-mt-4 p-mb-2">
                <Calendar monthNavigator yearNavigator  yearRange={`${new Date().getFullYear() - 50}:${new Date().getFullYear()}`} showIcon={true} value={start} maxDate={end} onChange={(e)=> setStart(e.value)} dateFormat="dd/mm/yy" locale="br" className="p-button-sm" />
                <strong style={{textAlign:'center'}}>Até</strong>
                <Calendar monthNavigator yearNavigator yearRange={`${new Date().getFullYear() - 50}:${new Date().getFullYear()}`} showIcon={true} value={end} minDate={start} onChange={(e)=> setEnd(e.value)} dateFormat="dd/mm/yy" locale="br" className="p-button-sm" />
                <Button onClick={()=> getDataDash()} label="Filtrar" className="p-button-sm" />
            </div>
            <header>
                <CardDashboardHeader title="Empresas" icon="pi pi-briefcase" value={0} cardcolor="var(--blue-100)" textcolor="var(--blue-900)" />
                <CardDashboardHeader title="Clientes" icon="pi pi-users" value={clientsData} cardcolor="var(--purple-100)" textcolor="var(--purple-900)" />
                <CardDashboardHeader title="Pedidos" icon="pi pi-list" value={requestsData} cardcolor="var(--yellow-100)" textcolor="var(--yellow-900)" />
                <CardDashboardHeader title="Total vendas" icon="pi pi-dollar" value={buysData} cardcolor="var(--green-100)" textcolor="var(--green-900)" />
            </header>
            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <div className="chart-area">
                        <h3>
                            Vendas
                        </h3>
                        <canvas style={{maxWidth:'600px', margin:'0 auto'}} ref={chartBuys} width="300" height="auto" ></canvas>
                    </div>
                </div>
                <div className="p-col-12 p-md-6">
                    <h3>
                        Vendas por vendedor
                    </h3>
                    <canvas style={{maxWidth:'600px', margin:'0 auto'}} ref={chartBuysVend} width="300" height="300" ></canvas>
                </div>
            </div>
        </div>
    )
}